import React, { useEffect, useState, useRef, useCallback } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { Button, Paper } from '@mui/material';
import { sk } from './globals.js';
import { styles } from './styles.js';
import { Line, LoadLanguage, GoHome, useScrollDirection } from './items.js';
import { Block } from '@mui/icons-material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { faCalendar, faCalendarCheck, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Parallax } from 'react-scroll-parallax';



export const Main = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const service_1 = require('./react/app/service_1.jpg');
    const service_2 = require('./react/app/service_2.jpg');
    const service_3 = require('./react/app/service_3.jpg');

    const photo_mobil = require('./react/app/mobil.png');
    const photo_tablet = require('./react/app/tablet.png');

    const photo_notebook = require('./react/app/notebook_2.png');
    const photo_1 = require('./react/app/photo_1.jpg');
    const photo_2 = require('./react/app/photo_2.jpg');
    const photo_3 = require('./react/app/photo_3.jpg');
    const photo_4 = require('./react/app/photo_4.jpg');
    const photo_5 = require('./react/app/photo_5.jpg');
    const photo_6 = require('./react/app/photo_6.jpg');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    var lang = sk;
    let { func } = props;


    useEffect(() => {
        func(true, 0); // page index
        GoHome();
        //db_web();

        return () => {
        };

    }, []);

    const db_web = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'price_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }

            setBusy(false);

        } catch (error) {
            //Debug(error);
            setBusy(false);
        }
    }

    return (
        <div style={{ ...styles.Block, position: 'relative', marginTop: 40, paddingBottom: 80, zIndex: 100 }}>

            <p style={{ ...styles.TextLarge, marginTop: 15 }}><b>Kontakt:</b> 0917 288 557</p>
        </div>
    )
}